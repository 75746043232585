import * as Sentry from '@sentry/browser';

const dsn = import.meta.env.VITE_SENTRY_LARAVEL_DSN;
const appUrl = import.meta.env.VITE_APP_URL;
const environment = import.meta.env.VITE_APP_ENV;

Sentry.init({
  dsn: dsn,
  environment: environment,
  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  //release: 'my-project-name@2.3.12',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [appUrl],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
